import { createMongoAbility } from '@casl/ability'
import { getUserData } from '@/router/utils'

export const initialAbility = [
  {
    action: 'read',
  },
]

const userData = getUserData()
const existingAbility = userData ? userData.ability : null
export default new createMongoAbility(existingAbility || initialAbility)
