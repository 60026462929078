<script setup>
import * as Sentry from '@sentry/browser'
import { useTheme } from 'vuetify'
import ScrollToTop from '@core/components/ScrollToTop.vue'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { hexToRgb } from '@layouts/utils'
import axios from '@axios'
import { useStore } from '@/stores'
import { EventSourcePolyfill } from 'event-source-polyfill'

const {
  syncInitialLoaderTheme,
  syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme,
  isAppRtl,
  handleSkinChanges,
} = useThemeConfig()

const { global } = useTheme()

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()
handleSkinChanges()

const emitter = inject('emitter')
const store = useStore()
const route = useRoute()
const router = useRouter()

onMounted(() => {
  if (store.mercureToken) {
    subscribeEvent()
  } else {
    axios.get('api/1/token')
      .then(({ data }) => {
        if (data.token) {
          store.updateMercureToken(data.token)
          subscribeEvent()
        }
      })
  }
  if (!store.isUserLoggedIn && !/(login|bypass)/.test(window.location.pathname)) {
    router.push('/login')
  }
  if (store.isUserLoggedIn) {
    Sentry.setUser({ id: store.userData?.id })
  }
})

onBeforeUnmount(() => {
  if (msgServer) msgServer.close()
  emitter.off()
})

let msgServer

function subscribeEvent() {
  const topics = ['web-deployed', 'assessment-{id}']

  const query = topics.join('&topic=')

  msgServer = new EventSourcePolyfill(`${import.meta.env.VITE_MERCURE_URL}?topic=${query}`, {
    headers: {
      Authorization: `Bearer ${store.mercureToken}`,
    },
    heartbeatTimeout: 1805000,
  })
  msgServer.addEventListener('message', message => {
    try {
      console.log(message)

      const { data } = message
      if (data) {
        const json = JSON.parse(data)
        if (json.event === 'web-deployed-0') {
          window.location.reload()
        } else {
          emitter.emit(json.event, json.data)
        }
      }
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log('Error:', ex)
    }
  })
}
</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <RouterView />
      <ScrollToTop />
    </VApp>
  </VLocaleProvider>
</template>
