import { PublicClientApplication } from "@azure/msal-browser"

const $msalInstance = new PublicClientApplication({
  auth: {
    clientId: import.meta.env.VITE_AZURE_CLIENT_ID,
    authority: import.meta.env.VITE_AZURE_AUTHORITY,
    redirectUri: import.meta.env.VITE_AZURE_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
})

await $msalInstance.initialize()
await $msalInstance.handleRedirectPromise() // This will no longer throw this error since initialize completed before this was invoked
// $msalInstance.acquireTokenSilent() // This will also no longer throw this error

export default $msalInstance
