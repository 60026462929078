const __pages_import_0__ = () => import("/src/pages/peers/subordinators.vue");
const __pages_import_1__ = () => import("/src/pages/peers/personal.vue");
const __pages_import_2__ = () => import("/src/pages/peers/head.vue");
const __pages_import_3__ = () => import("/src/pages/peers/colleagues.vue");
const __pages_import_4__ = () => import("/src/pages/second-page.vue");
const __pages_import_5__ = () => import("/src/pages/question.vue");
const __pages_import_6__ = () => import("/src/pages/profile.vue");
const __pages_import_7__ = () => import("/src/pages/products.vue");
const __pages_import_8__ = () => import("/src/pages/orders.vue");
const __pages_import_9__ = () => import("/src/pages/not-authorized.vue");
const __pages_import_10__ = () => import("/src/pages/login.vue");
import __pages_import_11__ from "/src/pages/index.vue";
const __pages_import_12__ = () => import("/src/pages/cart.vue");
const __pages_import_13__ = () => import("/src/pages/bypass.vue");
const __pages_import_14__ = () => import("/src/pages/[...all].vue");
const __pages_import_15__ = () => import("/src/pages/assessment/[id].vue");

const routes = [{"name":"peers-subordinators","path":"/peers/subordinators","component":__pages_import_0__,"props":true},{"name":"peers-personal","path":"/peers/personal","component":__pages_import_1__,"props":true},{"name":"peers-head","path":"/peers/head","component":__pages_import_2__,"props":true},{"name":"peers-colleagues","path":"/peers/colleagues","component":__pages_import_3__,"props":true},{"name":"second-page","path":"/second-page","component":__pages_import_4__,"props":true,"meta":{"action":"read"}},{"name":"question","path":"/question","component":__pages_import_5__,"props":true,"meta":{"action":"read"}},{"name":"profile","path":"/profile","component":__pages_import_6__,"props":true,"meta":{"action":"read"}},{"name":"products","path":"/products","component":__pages_import_7__,"props":true,"meta":{"action":"read"}},{"name":"orders","path":"/orders","component":__pages_import_8__,"props":true,"meta":{"action":"read"}},{"name":"not-authorized","path":"/not-authorized","component":__pages_import_9__,"props":true,"meta":{"layout":"blank"}},{"name":"login","path":"/login","component":__pages_import_10__,"props":true,"meta":{"layout":"blank"}},{"name":"index","path":"/","component":__pages_import_11__,"props":true,"meta":{"action":"read","requiresAuth":true}},{"name":"cart","path":"/cart","component":__pages_import_12__,"props":true,"meta":{"action":"read"}},{"name":"bypass","path":"/bypass","component":__pages_import_13__,"props":true,"meta":{"layout":"blank"}},{"name":"all","path":"/:all(.*)*","component":__pages_import_14__,"props":true,"meta":{"layout":"blank","action":"read","subject":"Auth"}},{"name":"peer","path":"/assessment/:id","component":__pages_import_15__,"props":true,"meta":{"action":"read","requiresAuth":true}}];

export default routes;