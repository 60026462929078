import { defineStore } from 'pinia'
import {
  getUserData,
  getHomeAccountId,
  isUserLoggedIn,
  getAccessToken,
  clearAllToken,
  setUserData,
  setAccessToken,
  getMercureToken,
  setMercureToken,
  setHomeAccountId,
}
  from '@/router/utils'
import axios from '@axios'
import ability, { initialAbility } from '@/plugins/casl/ability'

export const useStore = defineStore('store', {
  state: () => ({
    homeAccountId: getHomeAccountId(),
    userData: getUserData(),
    isUserLoggedIn: isUserLoggedIn(),
    accessToken: getAccessToken(),
    mercureToken: getMercureToken(),
    defaultLanguage: 'en',
    languages: [
      { code: 'en', name: 'English' },
    ],
  }),
  actions: {
    getUserData(refresh) {
      return axios.get(`/profile?refresh=${refresh}`)
        .then(({ data }) => {
          this.updateUserData(data)
        })
    },
    updateUserData(val) {
      if (!val) {
        clearAllToken()
        ability.update(initialAbility)
        this.isUserLoggedIn = false
        this.token = ''
        this.userData = null
      } else {
        setUserData(JSON.stringify(val.user))
        if (val.token) {
          setAccessToken(val.token)
          this.accessToken = val.token
        }
        if (val.user.ability) {
          ability.update(val.user.ability)
        }
        this.isUserLoggedIn = true
        this.userData = val.user
      }
    },
    updateMercureToken(val) {
      setMercureToken(val)
      this.mercureToken = val
    },
    updateHomeAccountId(val) {
      setHomeAccountId(val)
      this.homeAccountId = val
    },
    logout() {
      clearAllToken()
      ability.update(initialAbility)
      this.isUserLoggedIn = false
      this.userData = null
      this.accessToken = null
      this.mercureToken = null
      this.homeAccountId = null
    },
  },
})
