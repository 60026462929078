import axios from 'axios'
import { getAccessToken } from '@/router/utils'

const axiosIns = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
})

axiosIns.interceptors.request.use(
  config => {
    const token = getAccessToken()
    if (token) {
      config.headers = config.headers || {}
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  // eslint-disable-next-line promise/no-promise-in-callback
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(response => response,
  error => {
    try {
      if (error.response && [401, 403].includes(error.response.status) && !window.location.href.match(/login|register/)) {
        localStorage.removeItem('userData')
        localStorage.removeItem('accessToken')
        setTimeout(() => {
          window.location = '/login'
        }, 300)

        // eslint-disable-next-line consistent-return
        return Promise.reject(error)
      }
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log(ex)
    }

    // eslint-disable-next-line consistent-return
    return Promise.reject(error)
  })

export default axiosIns
