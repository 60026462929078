/* eslint-disable import/order */
import '@/@iconify/icons-bundle'
import App from '@/App.vue'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import router from '@/router'
import { abilitiesPlugin } from '@casl/vue'
import ability from '@/plugins/casl/ability'
import i18n from '@/plugins/i18n'
import emitter from '@/plugins/emitter'
import Swal from 'sweetalert2'
import $msalInstance from "@/plugins/azure"
import * as Sentry from '@sentry/vue'

import 'sweetalert2/src/sweetalert2.scss'
import '@core/scss/template/index.scss'
import '@styles/styles.scss'
import { createPinia } from 'pinia'
import { createApp } from 'vue'

loadFonts()

// Create vue app
const app = createApp(App)

app.provide('emitter', emitter)
app.provide('$swal', Swal)
app.provide('$msalInstance', $msalInstance)

// Use plugins
app.use(vuetify)
app.use(createPinia())
app.use(router)
app.use(layoutsPlugin)
app.use(i18n)
app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
})

Sentry.init({
  app,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  maxBreadcrumbs: 50,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  tracesSampleRate: import.meta.env.VITE_SENTRY_RATE || 1.0,
})

// Mount vue app
app.mount('#app')
