/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
const keyToken = 'accessToken'
const keyUser = 'userData'
const keyMercure = 'mercureToken'
const keyAccountId = 'accountId'

export const isUserLoggedIn = () => !!(localStorage.getItem(keyUser) && localStorage.getItem(keyToken))

export const getUserData = () => JSON.parse(localStorage.getItem(keyUser))

export const getHomeAccountId = () => localStorage.getItem(keyAccountId) ?? ''

export const getAccessToken = () => localStorage.getItem(keyToken) ?? ''

export const getMercureToken = () => localStorage.getItem(keyMercure) ?? ''

export const setUserData = data => {
  localStorage.setItem(keyUser, data)
}

export const setAccessToken = str => {
  localStorage.setItem(keyToken, str)
}

export const clearAllToken = () => {
  localStorage.removeItem(keyToken)
  localStorage.removeItem(keyUser)
  localStorage.removeItem(keyMercure)
}

export const setMercureToken = str => {
  localStorage.setItem(keyMercure, str)
}

export const setHomeAccountId = str => {
  localStorage.setItem(keyAccountId, str)
}
