<script setup>
import axios from "@axios"

const assessments = ref([])

onMounted(() => {
  getItems()
})

const getItems = () => {
  axios.get('api/0/assessments')
    .then(({ data }) => {
      if (data.success) {
        assessments.value = data.data
      }
    })
}
</script>

<template>
  <VContainer>
    <VCard title="รอบการประเมิน" class="mb-6">
      <VCardText>
        <VList
          lines="two"
          border
        >
          <template
            v-for="(assessment, index) in assessments"
            :key="index"
          >
            <VListItem
              link
              :to="assessment.status == 1 ? {
                name: 'peer',
                params: { id: assessment.id },
              } : ''"
            >
              <VListItemTitle>
                {{ assessment.title }}
              </VListItemTitle>

              <template #append>
                <VChip
                  size="default"
                  :color="{ '0': 'warning', '1': 'success', '2': 'error' }[assessment.status]"
                >
                  {{ assessment.status_text }}
                </VChip>
              </template>
            </VListItem>
            <VDivider v-if="index !== assessments.length - 1" />
          </template>
        </VList>
      </VCardText>
    </VCard>
  </VContainer>
</template>

<route lang="yaml">
meta:
  action: read
  requiresAuth: true
</route>
